<template>
  <b-container>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-card-title>
            Información del paquete de créditos
          </b-card-title>
          <validation-observer ref="formValidation">
            <b-form @submit.prevent="validateForm()">
              <b-form-group label="Créditos">
                <validation-provider
                  #default="{ errors }"
                  name="Créditos"
                  rules="required"
                >
                  <b-form-input
                    v-model="creditPackage.credit_amount"
                    placeholder="Créditos"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Precio">
                <validation-provider
                  #default="{ errors }"
                  name="Precio"
                  rules="required"
                >
                  <b-form-input
                    v-model="creditPackage.price"
                    placeholder="Precio"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Descripción"
              >
                <b-form-textarea
                  v-model="creditPackage.description"
                  rows="3"
                />
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
              >
                Guardar
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      creditPackage: {
        credit_amount: 0,
        is_recommended: false,
        price: 0.0,
        active_status: 'active',
        description: '',
      },
    }
  },
  beforeMount() {
    if (this.$route.params.credit_id) {
      this.getCreditPackage(this.$route.params.credit_id)
        .then(response => {
          this.creditPackage = response
        })
    }
  },
  methods: {
    ...mapActions('credits', ['addCreditPackage', 'editCreditPackage', 'getCreditPackages', 'getCreditPackage']),
    validateForm() {
      if (this.$route.params.credit_id) {
        this.$refs.formValidation.validate().then(success => {
          if (success) {
            this.editCreditPackage(this.creditPackage)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Paquete de créditos editada',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .then(() => {
                this.$router.push({ name: 'credits-bundle' })
              })
          }
        })
      } else {
        this.$refs.formValidation.validate().then(success => {
          if (success) {
            this.addCreditPackage(this.creditPackage)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Paquete de créditos creado',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
              })
              .then(() => {
                this.$router.push({ name: 'credits-bundle' })
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
